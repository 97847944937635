/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(".imgLiquidFill").imgLiquid();
      },
      finalize: function() {
        $('.brands-slider').slick({
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            draggable: true,
            accessibility: true,
            infinite: true, 
            slidesToShow: 5,
            slidesToScroll: 5,
            nextArrow: '<i class="fa fa-chevron-right"></i>',
            prevArrow: '<i class="fa fa-chevron-left"></i>',
        });

        $('.testimonials-slider').slick({
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            draggable: true,
            accessibility: true,
            infinite: true, 
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: '<i class="fa fa-chevron-right"></i>',
            prevArrow: '<i class="fa fa-chevron-left"></i>',
        });

        $('.sites-slider').slick({
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            draggable: true,
            accessibility: true,
            infinite: true, 
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: '<i class="fa fa-chevron-right"></i>',
            prevArrow: '<i class="fa fa-chevron-left"></i>',
            responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
          ]
        });
        
        $('.header_slider').slick({
            dots: true,
            arrows: false,
            infinite: true,
            autoplay: false,
            autoplaySpeed: 3000,
            draggable: true,
            accessibility: true,
            lazyLoad: 'ondemand',
        });

        $(document).ready(function() {
            $('.faq_question').click(function() {
                if ($(this).parent().is('.open')){
                    $(this).closest('.faq').find('.faq_answer_container').animate({'height':'0'},500);
                    $(this).closest('.faq').removeClass('open');
                    }else{
                        var newHeight =$(this).closest('.faq').find('.faq_answer').height() +'px';
                        $(this).closest('.faq').find('.faq_answer_container').animate({'height':newHeight},500);
                        $(this).closest('.faq').addClass('open');
                    }
            });
            
            $( "#showcart" ).click(function() {
              $( ".widget_shopping_cart_content" ).toggleClass( "active" ); 
            });
         
        });
        
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Single
    'single': {
      init: function() {
        // JavaScript to be fired on the single page
      },
      finalize: function() {
        $('.header_slider').slick({
            centerMode: true,
            slidesToShow: 3,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 1
                }
              }
            ],
            dots: true,
            arrows: true,
            nextArrow: '<i class="fa fa-chevron-right"></i>',
            prevArrow: '<i class="fa fa-chevron-left"></i>',
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            draggable: true,
            accessibility: true,
            lazyLoad: 'ondemand', 
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }, 
    'contact': {
      init: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
